import { FunctionComponent } from "react";
import Button from "../components/Buttons/login-button";
import { loginRequest } from "../config/authConfig";
import { useMsal } from "@azure/msal-react";
import React from "react";
import { removeData } from "../utils/local-storage";
import { STORAGE_KEYS } from "../utils/enums";
const LoginPage: FunctionComponent = () => {
    const { instance } = useMsal();

    const msLoginSubmit = async () => {
        try {
            await instance.loginRedirect(loginRequest);
            await removeData(STORAGE_KEYS.CURRENT_CONVERSATION_ID);
        } catch (error) {
            /* eslint-disable no-console */
            console.error(error);
        }
    };
    return (
        <div className="px-0 bg-ghostwhite relative w-full flex flex-row items-center justify-center box-border max-h-full h-screen">
            <div className="h-[344px] flex flex-col items-center justify-between lg:w-auto lg:[align-self:unset]">
                <img
                    className="relative w-[260px] h-[133px] object-cover mix-blend-multiply"
                    alt="sam-nordic-logo"
                    loading="lazy"
                    src="/logo@3x.png"
                />
                <Button text="Login" btnOnClick={msLoginSubmit} />
            </div>
        </div>
    );
};

export default LoginPage;
