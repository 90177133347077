import Axios, { AxiosInstance } from "axios";

export class APIHelper {
    static _axiosInstance: AxiosInstance = null as any;
    static refreshTokenPromise: Promise<any> | null = null;

    static api(authData: any, params?: { [key: string]: any }): AxiosInstance {
        APIHelper._axiosInstance = Axios.create({
            baseURL: authData.baseUrl,
            timeout: 3000 * 60,
        });

        const setAuthHeaders = (authKey: string) => {
            APIHelper._axiosInstance.interceptors.request.use(
                (config: any) => {
                    config.headers["Authorization"] = `Bearer ${authKey}`;
                    config.headers["Content-Type"] = "application/json";
                    config.headers["Accept"] = "application/json";
                    if (params) {
                        config.params = params;
                    }
                    return config;
                },
                (error) => {
                    Promise.reject(error);
                },
            );
        };

        if (authData.authKey) {
            setAuthHeaders(authData.authKey);
        }

        return this._axiosInstance;
    }
}
