import React from "react";

type PropTypes = {
    btnOnClick?: () => void;
    text: string;
};

const Button = ({ btnOnClick, text }: PropTypes) => {
    return (
        <button
            className="box-border flex flex-row  items-center justify-center cursor-pointer bg-primary-color text-base-white font-museo-sans border-[1px] border-solid border-primary-color w-[260px] min-h-[24px] h-[48px] text-[16px]"
            onClick={btnOnClick}
        >
            {text}
        </button>
    );
};

export default Button;
