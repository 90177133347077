/* eslint-disable   */
import { useEffect, useState } from "react";
import { FunctionComponent, useMemo, type CSSProperties } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { markdownEditor } from "./markdown";
// import { toast } from "react-toastify";

type StyleUserType = {
    messageText?: string;
    message?: string;
    dimensionText?: string;
    showIcon?: boolean;
    icon?: boolean;
    isUser: boolean;
    loadedFromHistory: boolean;
    loading?: boolean;
    isSibling?: boolean;
    siblingsArray?: [];
    messageObject?: any;
    showNextSibling?: (id: any, index: number, conversationId: string) => void;
    showPreviousSibling?: (
        id: any,
        index: number,
        conversationId: string,
    ) => void;
    conversationId: string;
    /** Style props */
    styleUserBackgroundColor?: CSSProperties["backgroundColor"];
    messageTextMargin?: CSSProperties["margin"];
    copyButtonCursor?: CSSProperties["cursor"];
    copyButtonBorder?: CSSProperties["border"];
    copyButtonPadding?: CSSProperties["padding"];
    copyButtonBackgroundColor?: CSSProperties["backgroundColor"];
    buttonBaseBoxSizing?: CSSProperties["boxSizing"];
};

const StyleUser: FunctionComponent<StyleUserType> = ({
    messageText,
    message = "Message text",
    dimensionText,
    showIcon = false,
    styleUserBackgroundColor,
    messageTextMargin,
    copyButtonCursor,
    copyButtonBorder,
    copyButtonPadding,
    copyButtonBackgroundColor,
    buttonBaseBoxSizing,
    isUser,
    loadedFromHistory,
    loading,
    isSibling,
    siblingsArray,
    showNextSibling,
    showPreviousSibling,
    messageObject,
    conversationId,
}) => {
    const styleUserStyle: CSSProperties = useMemo(() => {
        return {
            backgroundColor: styleUserBackgroundColor,
        };
    }, [styleUserBackgroundColor]);

    const messageTextStyle: CSSProperties = useMemo(() => {
        return {
            margin: messageTextMargin,
        };
    }, [messageTextMargin]);

    const copyButtonStyle: CSSProperties = useMemo(() => {
        return {
            cursor: copyButtonCursor,
            border: copyButtonBorder,
            padding: copyButtonPadding,
            backgroundColor: copyButtonBackgroundColor,
        };
    }, [
        copyButtonCursor,
        copyButtonBorder,
        copyButtonPadding,
        copyButtonBackgroundColor,
    ]);

    const buttonBase1Style: CSSProperties = useMemo(() => {
        return {
            boxSizing: buttonBaseBoxSizing,
        };
    }, [buttonBaseBoxSizing]);

    const renderMessage = () => {
        if (isUser) {
            return (
                <p className="mt-[7px] text-[16px] sm:text-[12px]">{message}</p>
            );
        } else {
            if (loadedFromHistory) {
                return markdownEditor(message);
            } else {
                return markdownEditor(message);
            }
        }
    };
    const [currentIndex, setCurrentIndex] = useState(1);

    const handleNext = () => {
        if (currentIndex === siblingsArray.length) {
            return null;
        } else {
            setCurrentIndex((prevIndex) => prevIndex + 1);
            showNextSibling(messageObject.id, currentIndex + 1, conversationId);
        }
    };

    const handlePrevious = () => {
        if (currentIndex === 1) {
            return null;
        } else {
            setCurrentIndex((prevIndex) => prevIndex - 1);
            showPreviousSibling(
                messageObject.id,
                currentIndex - 1,
                conversationId,
            );
        }
    };

    useEffect(() => {
        selectHandle();
    });

    const selectHandle = () => {
        if (isSibling) {
            const getID = messageObject.siblings.indexOf(messageObject.id);
            setCurrentIndex(getID + 1);
        }
    };

    return (
        <div
            className="flex flex-row items-center justify-start py-4  px-[45px] sm:px-[20px] box-border gap-[24px] text-left text-base text-gray font-museo-sans self-stretch relative"
            style={styleUserStyle}
        >
            <div
                className={`flex-1 flex flex-row items-start justify-center gap-[24px]`}
            >
                <div className="">
                    {isSibling && (
                        <div className="multiple-answers flex flex-row items-start justify-start absolute left-[10px] top-[30px] text-[12px] ">
                            <a
                                onClick={handlePrevious}
                                className="left-icon rotate-180 cursor-pointer text-[10px]"
                            ></a>
                            {currentIndex}/{siblingsArray?.length}
                            <a
                                onClick={handleNext}
                                className="right-icon cursor-pointer"
                            ></a>
                        </div>
                    )}
                </div>
                <div className={`flex flex-col items-start justify-start `}>
                    <img
                        className="relative rounded-[50%] w-[34px] h-[34px] object-cover"
                        alt=""
                        src={messageText}
                    />
                </div>
                <div className="flex-1 flex flex-row items-center justify-center">
                    <div
                        className="flex-1 relative leading-[24px]"
                        style={messageTextStyle}
                    >
                        {loading ? (
                            <img
                                className="relative rounded-[50%] w-[34px] h-[34px] object-cover"
                                alt=""
                                src={require("../assets/images/loading.gif")}
                            />
                        ) : (
                            renderMessage()
                        )}
                    </div>
                </div>
            </div>
            {showIcon && (
                <div className="w-8 flex-row items-center justify-end bg-red">
                    <div
                        className="flex flex-row flex-wrap items-start justify-start "
                        style={copyButtonStyle}
                    >
                        <div
                            className="flex flex-row items-start justify-start p-1 "
                            style={buttonBase1Style}
                        >
                            <div className="flex flex-row items-start justify-center ">
                                <CopyToClipboard
                                    text={`${message}`}
                                    onCopy={() => {
                                        // toast.success("Copied !", {
                                        //     position: "top-center",
                                        // });
                                    }}
                                    className="w-4 h-4 flex flex-row items-center justify-center "
                                >
                                    <img
                                        className="relative w-3 h-[13.33px]"
                                        alt=""
                                        src={dimensionText}
                                    />
                                </CopyToClipboard>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default StyleUser;
