import { useState } from "react";
import ModenavOpen from "../../components/modenav-open";
// import BtnProfile from "../../components/btn-profile";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import React from "react";
import MobileHeader from "../mobile-header/index";

function signOutClickHandler(
    instance: IPublicClientApplication,
    account: AccountInfo,
) {
    const logoutRequest = {
        account: account,
        mainWindowRedirectUri: "/",
        postLogoutRedirectUri: "/",
    };
    instance.logoutPopup(logoutRequest);
}

const Page = () => {
    const { instance, accounts } = useMsal();
    const [openStatus, setOpenStatus] = useState(false);
    const [mobileSidebar, setMobileSidebar] = useState(true);
    // Function to find the AccountInfo based on homeAccountId
    const findAccountByHomeAccountId = (
        homeAccountId: string,
    ): AccountInfo | undefined => {
        return accounts.find(
            (account) => account.homeAccountId === homeAccountId,
        );
    };
    const logOut = () => {
        const homeAccountId = accounts[0].homeAccountId;
        const accountToSignOut = findAccountByHomeAccountId(homeAccountId);
        if (accountToSignOut) {
            signOutClickHandler(instance, accountToSignOut);
        } else {
            /* eslint-disable no-console */
            console.error("Account not found for the given homeAccountId");
        }
    };

    const toggleMobileSidebar = () => {
        setOpenStatus(!openStatus);
        setMobileSidebar(!mobileSidebar);
    };

    const toggle = () => {
        setOpenStatus(!openStatus);
    };
    return (
        <div>
            <MobileHeader
                toggle={toggleMobileSidebar}
                // onNewThread={newThreadOnClick}
            />
            <div className="relative bg-ghostwhite w-full h-screen overflow-hidden flex flex-row">
                <div
                    className={`flex  ${
                        openStatus ? "w-[80px] sm:w-[0px]" : "w-[250px]"
                    } h-full sidebar-wrapper  ease-in-out duration-200 bg-primary-color fixed top-0 bottom-0 left-0 sm:absolute sm:top-0 sm:bottom-0 sm:left-[0px] sm:z-50`}
                >
                    <ModenavOpen
                        logoImage="/image-1@3x.png"
                        dimensionLabel="/vectors10.svg"
                        showLog
                        logoutUser={logOut}
                        toggle={toggle}
                        openStatus={openStatus}
                    />
                </div>
                <main
                    className={` ${
                        openStatus
                            ? "sm:w-[0px] xl:w-[80px] xl:ml-[80px] sm:ml-[0px]"
                            : " sm:w-[0px] xl:w-[250px] xl:ml-[250px] sm:ml-[0px]"
                    } flex-1 min-w-0 overflow-auto relative text-left ease-in-out duration-200`}
                >
                    <div className="self-stretch flex flex-row flex-wrap items-center justify-between py-16 px-16 lg:flex-row lg:gap-[24px] lg:items-center lg:justify-start md:flex-row md:gap-[24px] md:items-center md:justify-start sm:w-full sm:h-auto sm:flex-row sm:gap-[24px] sm:items-center sm:justify-between sm:pl-4 sm:pr-4 sm:box-border">
                        <div className="flex-1 h-[58px] flex flex-col items-start justify-start py-[20px] px-0 box-border sm:w-[85%]">
                            <p className="m-0 flex-1 relative text-[28px] font-normal font-museo-sans sm:text-[20px]">
                                Oops! Something went wrong.
                            </p>
                            <p className="text-[20px] sm:text-[16px] font-normal font-museo-sans pt-[40px]">
                                An error has occurred, Here are a few things you
                                can try:
                            </p>

                            <ul className="list-none pl-20px ">
                                <li className="font-museo-sans text-[16px] sm:text-[12px] py-[5px]">
                                    1. Refresh the page
                                </li>
                                <li className="font-museo-sans text-[16px] sm:text-[12px] py-[5px]">
                                    2. Check your internet connection
                                </li>
                                <li className="font-museo-sans text-[16px] sm:text-[12px] py-[5px]">
                                    3. Wait a few seconds and try again
                                </li>
                            </ul>

                            <p className="font-museo-sans text-[18px] sm:text-[12px]">
                                If the problem persists, send us an email at{" "}
                                <a
                                    href="mailto:ai@ideabits.se"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    info@samnordic.se
                                </a>
                            </p>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Page;
