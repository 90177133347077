import React from "react";
import EdiText from "react-editext";
import swal from "sweetalert";
import { ChatService } from "../../services/chat-service";
import { useMsal } from "@azure/msal-react";
import { tokenHandler } from "../../services/get-token";
import { toast } from "react-toastify";
import { removeData } from "../../utils/local-storage";
import { STORAGE_KEYS } from "../../utils/enums";

type Task = {
    id: string;
    title: string;
    create_time: string;
    update_time: string;
    conversation_template_id: string;
    current_node: string;
    gizmo_id: string;
};
type MonthCategory = {
    month: string;
    tasks: any;
    onClick?: (id: string) => void;
    selectedConversation?: string;
    getFirstPageChatHistory: () => Promise<void>;
    clearConversation?: () => void;
};

const SideBar: React.FC<MonthCategory> = ({
    month,
    tasks,
    onClick,
    selectedConversation,
    getFirstPageChatHistory,
    clearConversation,
}) => {
    const { instance } = useMsal();

    const onEditSave = async (id: string, newTitle: string) => {
        const token = await tokenHandler(instance);

        await ChatService.editConversationTitle(token, id, newTitle);
        await getFirstPageChatHistory();
    };

    const onDelete = async (id: string, title: string) => {
        swal({
            title: "Are you sure you want to delete this conversation?",
            text: `This will delete ${title}.`,
            buttons: ["Cancel", "Delete"],
            dangerMode: true,
        })
            .then(async (willDelete) => {
                if (willDelete) {
                    const token = await tokenHandler(instance);
                    const response = await ChatService.deleteConversation(
                        token,
                        id,
                    );
                    if (response) {
                        await removeData(STORAGE_KEYS.CURRENT_CONVERSATION_ID);
                    }
                    await getFirstPageChatHistory();
                    await clearConversation();
                }
            })
            .catch((error) => {
                if (error?.response?.status === 403) {
                    toast.error(error.response.data.error, {
                        position: "top-center",
                    });
                }
            });
    };

    return (
        <div className="item-list pb-[38px]">
            <div className="self-stretch flex flex-col items-start text-xs text-royalblue ">
                <p className="text-[12px] font-museo-sans uppercase">{month}</p>
            </div>
            <div className="list pt-[5px]">
                <ul className="list-none m-0 p-0 pl-[5px] pt-[20px]">
                    {tasks?.map((item: Task, index: number) => {
                        const title = item.title.replace('"', "");
                        const isThisItemSelected =
                            selectedConversation === item.id;
                        return (
                            <SideBarItem
                                key={item.id}
                                index={index}
                                id={item.id}
                                isThisItemSelected={isThisItemSelected}
                                title={title}
                                onItemClick={onClick}
                                onEditSave={onEditSave}
                                onDelete={onDelete}
                            />
                        );
                    })}
                </ul>
            </div>
        </div>
    );
};

type SideBarItemProps = {
    index: number;
    id: string;
    isThisItemSelected: boolean;
    title: string;
    onItemClick: (id: string) => void;
    onEditSave: (id: string, newTitle: string) => Promise<void>;
    onDelete: (id: string, title: string) => Promise<void>;
};

const SideBarItem = ({
    id,
    index,
    isThisItemSelected,
    title,
    onItemClick,
    onEditSave,
    onDelete,
}: SideBarItemProps) => {
    return (
        <li
            className={`py-[10px] overflow-hidden truncate w-[calc(100%-40px)] text-primary-grey cursor-pointer pl-[15px] min-h-[18px]  pr-[7px] flex flex-row relative ${
                isThisItemSelected
                    ? "bg-royalblue  rounded-[10px] text-white"
                    : ""
            }`}
            key={index}
            onClick={() => onItemClick(id)}
        >
            {isThisItemSelected ? (
                <>
                    <EdiText
                        type="text"
                        value={title}
                        viewContainerClassName="edit-thread-name-container flex flex-row"
                        onSave={(newTitle: string) => onEditSave(id, newTitle)}
                        submitOnEnter
                    />
                    <button
                        onClick={async () => {
                            await onDelete(id, title);
                        }}
                        className="absolute top-0 bottom-0 right-0 bg-royalblue border-none pr-[12px] cursor-pointer"
                    >
                        <img
                            src={require("../../assets/images/delete.png")}
                            className="w-[15px] h-[15px] mt-[3px]"
                        />
                    </button>
                </>
            ) : (
                title
            )}
        </li>
    );
};

export default SideBar;
