import { useEffect } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter, useNavigate } from "react-router-dom"; // useNavigate
import {
    PublicClientApplication,
    EventType,
    EventMessage,
    AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./config/authConfig";
import { MsalProvider } from "@azure/msal-react";
import { AuthProvider } from "./context/authContext";
import "./global.css";
import React from "react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "./components/error-boundary/index";

const msalInstance = new PublicClientApplication(msalConfig);

//get initialize msalInstance
msalInstance.initialize();
const activeAccount = msalInstance.getActiveAccount();

if (!activeAccount) {
    // Account selection
    const accounts = msalInstance.getAllAccounts();
    if (accounts.length > 0) {
        msalInstance.setActiveAccount(accounts[0]);
    }
}

//set the account
msalInstance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
        const authenticationResult = event.payload as AuthenticationResult;
        const account = authenticationResult.account;
        msalInstance.setActiveAccount(account);
    }
});

// //enable account storage event
msalInstance.enableAccountStorageEvents();

//main component
function MainComponent() {
    const navigate = useNavigate();

    useEffect(() => {
        msalInstance.handleRedirectPromise().then((response) => {
            if (response && response.account) {
                // User is authenticated, you can proceed to  app
                navigate("/preview", { replace: true });
            }
        });
        // Check if the user is already signed in
        const account = msalInstance.getActiveAccount();
        if (account) {
            // User is already signed in, you can proceed to  app
            navigate("/preview", { replace: true });
        } else {
            // If the user is not signed in, initiate the login process
            // msalInstance.initialize();
        }
    }, []);

    return <App />;
}

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <MsalProvider instance={msalInstance}>
        <BrowserRouter>
            <AuthProvider>
                <ErrorBoundary fallback={<ErrorPage />}>
                    <MainComponent />
                    <ToastContainer />
                </ErrorBoundary>
            </AuthProvider>
        </BrowserRouter>
    </MsalProvider>,
);

reportWebVitals();
