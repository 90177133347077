/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect } from "react";
import { FunctionComponent, type CSSProperties, useRef, useState } from "react";

type ChatInputType = {
    vector?: string;
    loading?: boolean;
    onFocus?: boolean;
    /** Style props */
    chatInputJustifyContent?: CSSProperties["justifyContent"];
    chatInputPadding?: CSSProperties["padding"];
    chatInputBoxSizing?: CSSProperties["boxSizing"];
    chatInputBorder?: CSSProperties["border"];
    chatInputFontFamily?: CSSProperties["fontFamily"];
    chatInputFontSize?: CSSProperties["fontSize"];
    chatInputGap?: CSSProperties["gap"];
    inputSubmitJustifyContent?: CSSProperties["justifyContent"];
    enterButtonCursor?: CSSProperties["cursor"];
    enterButtonBorder?: CSSProperties["border"];
    enterButtonPadding?: CSSProperties["padding"];
    enterButtonBackgroundColor?: CSSProperties["backgroundColor"];
    onSubmit: (value: string) => void;
};

const ChatInput: FunctionComponent<ChatInputType> = ({
    onSubmit,
    loading,
    onFocus,
}) => {
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        inputRef.current.focus();
        inputRef.current.style.height = "56px";
        setInputValue("");
    }, [onFocus]);

    const handleChange = (event: any) => {
        setInputValue(event.target.value);
    };

    const enterPress = (e: React.KeyboardEvent) => {
        if (e.keyCode === 13 && e.shiftKey === false) {
            e.preventDefault();
            if (inputValue.length > 0) {
                onSubmit(inputValue);
                setInputValue("");
            }
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (inputValue.length > 0) {
            setInputValue("");
            onSubmit(inputValue);
        }
    };
    const inputRef = useRef<HTMLInputElement>(null);

    const scrolltoBottom = () => {
        window.scrollTo(400, 0);
        // inputRef.current.focus();
    };

    useEffect(() => {
        inputRef.current.style.height = "56px";
        const scrollHeight = inputRef.current.scrollHeight;
        inputRef.current.style.height = scrollHeight + "px";
    }, [inputValue]);

    return (
        <div className="flex flex-col w-full relative drop-shadow-xl">
            <textarea
                ref={
                    inputRef as unknown as React.RefObject<HTMLTextAreaElement>
                }
                className={` bg-base-white flex flex-row flex-wrap items-center resize-none justify-between p-4 box-border text-left text-black placeholder:text-lightslategray font-museo-sans self-stretch max-h-[200px] h-[56px] overflow-y-auto text-[16px] pr-[45px] focus:outline-none font-light`}
                placeholder="Send a message"
                rows={1}
                onChange={handleChange}
                onKeyDown={enterPress}
                onBlur={scrolltoBottom}
                value={inputValue}
                disabled={loading}
                autoFocus
            />
            <button onTouchEnd={handleSubmit} onClick={handleSubmit}>
                <img
                    src={require("../assets/images/submit-icon.png")}
                    className="w-[20px] absolute right-[15px] top-[16px] cursor-pointer"
                />
            </button>
        </div>
    );
};

export default ChatInput;
