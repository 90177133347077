import React, { useEffect, useState } from "react";
import { getData, setData } from "../../utils/local-storage";
import { STORAGE_KEYS } from "../../utils/enums";

const GptSwitch = ({ disable }: any) => {
    const [isChecked, setIsChecked] = useState(false);

    useEffect(() => {
        setGptVersion();
    });

    const setGptVersion = async () => {
        const gptVersion = await getData(STORAGE_KEYS.CHATGPT);
        if (gptVersion === STORAGE_KEYS.CHATGPT_VERSION_35) {
            setIsChecked(false);
        }
        if (gptVersion === STORAGE_KEYS.CHATGPT_VERSION_4) {
            setIsChecked(true);
        }
    };

    const handleCheckboxChange = async () => {
        setIsChecked(!isChecked);
        if (isChecked) {
            await setData(
                STORAGE_KEYS.CHATGPT,
                STORAGE_KEYS.CHATGPT_VERSION_35,
            );
        } else {
            await setData(STORAGE_KEYS.CHATGPT, STORAGE_KEYS.CHATGPT_VERSION_4);
        }
    };
    return (
        <>
            <label className="themeSwitcherTwo shadow-card relative inline-flex cursor-pointer select-none items-center justify-center rounded-md bg-white p-1">
                <input
                    type="checkbox"
                    className="sr-only"
                    checked={isChecked}
                    onChange={handleCheckboxChange}
                    disabled={disable}
                />
                <span
                    className={`flex items-center space-x-[6px] rounded py-2 px-[18px] text-sm font-medium font-museo-sans ${
                        !isChecked
                            ? "text-primary bg-[#f4f7ff]"
                            : "text-body-color"
                    }`}
                >
                    <img
                        src={require("../../assets/images/chatgpt-icon.png")}
                        className="w-[15px] pr-[10px]"
                    />
                    GPT-3.5
                </span>
                <span
                    className={`flex items-center space-x-[6px] font-museo-sans rounded py-2 px-[18px] text-sm font-medium ${
                        isChecked
                            ? "text-primary bg-[#f4f7ff]"
                            : "text-body-color"
                    }`}
                >
                    <img
                        src={require("../../assets/images/chatgpt-icon.png")}
                        className="w-[15px] pr-[10px]"
                    />
                    GPT-4
                </span>
            </label>
        </>
    );
};

export default GptSwitch;
