/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    FunctionComponent,
    useState,
    useEffect,
    RefObject,
    useRef,
} from "react";
import ModenavOpen from "../../components/modenav-open";
import BtnProfile from "../../components/btn-profile";
import StyleUser from "../../components/style-user";
import FormButton from "../../components/form-button";
import { useMsal, MsalAuthenticationTemplate } from "@azure/msal-react";
import {
    InteractionType,
    IPublicClientApplication,
    AccountInfo,
} from "@azure/msal-browser";
import { useToken } from "../../context/authContext";
import MobileHeader from "../../components/mobile-header";
import ScrollToBottom from "react-scroll-to-bottom";
import React from "react";
import { isMobile, isTablet } from "react-device-detect";
import ProtectedComponent from "../../utils/protected-component";
import { ChatService } from "../../services/chat-service";
import { APIConstants } from "../../services/api-constant";
import ChatLoading from "../../components/chat-loading";
import { tokenHandler } from "../../services/get-token";
import GptSwitch from "../../components/switch/index";
import { getData, setData, removeData } from "../../utils/local-storage";
import { STORAGE_KEYS } from "../../utils/enums";
import { useErrorBoundary } from "react-error-boundary";

function signOutClickHandler(
    instance: IPublicClientApplication,
    account: AccountInfo,
) {
    const logoutRequest = {
        account: account,
        mainWindowRedirectUri: "/",
        postLogoutRedirectUri: "/",
    };
    instance.logoutPopup(logoutRequest);
}

interface Task {
    id: string;
    title: string;
    create_time: string;
    update_time: string;
    updated_at: string;
    // Add other properties as needed
}

type ChatMessage = {
    today: Task[];
    yesterday: Task[];
    older: Task[];
};

const ConversationPage: FunctionComponent = () => {
    const { token } = useToken();
    const { showBoundary } = useErrorBoundary();
    const { instance, accounts } = useMsal();
    const [openStatus, setOpenStatus] = useState(isMobile || isTablet);
    const [mobileSidebar, setMobileSidebar] = useState(true);
    const [chatHistory, setChatHistory] = useState({});
    const [conversation, setConversation] = useState<any[]>([]);
    const [originalConversation, setOriginalConversation] = useState<any[]>([]);
    const mainMapping: any = useRef();
    const listInnerRef: RefObject<HTMLDivElement> = React.useRef(null);
    const [currPage, setCurrPage] = useState(0);
    const [prevPage, setPrevPage] = useState(0);
    const [typeStart, setTypeStart] = useState(false);
    const [lastList, setLastList] = useState(false);
    const [image, setImage] = useState<string | ArrayBuffer>("");
    const [initialConversation, setInitialConversation] = useState(true);
    const [gptModel, setGptModel] = useState(null);
    const [steamStatus, setStreamStatus] = useState(false);
    const [selectedConversationID, setSelectedConversationID] = useState("");
    const [inputFocus, setInputFocus] = useState(false);
    const [error, setErorr] = useState({ code: 0, message: "" });
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const handleResize = () => {
        setWindowWidth(window.innerWidth);

        if (windowWidth < 960) {
            setOpenStatus(true);
        }
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);

        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [windowWidth]);

    useEffect(() => {
        if (token) {
            getChatHistory();
            GetProfilePicture();
        }
    }, [token]);

    useEffect(() => {
        setInputFocus(!inputFocus);
    }, [selectedConversationID]);

    // Function to find the AccountInfo based on homeAccountId
    const findAccountByHomeAccountId = (
        homeAccountId: string,
    ): AccountInfo | undefined => {
        return accounts.find(
            (account) => account.homeAccountId === homeAccountId,
        );
    };

    useEffect(() => {
        if (token) {
            loadConversation();
        }
    }, [token]);

    const loadConversation = async () => {
        const data = await getData(STORAGE_KEYS.CURRENT_CONVERSATION_ID);
        if (data) {
            conversationOnClick(data);
        }
    };

    useEffect(() => {
        if (conversation.length >= 2) {
            setInitialConversation(false);
        }
    }, [conversation]);

    useEffect(() => {
        if (!lastList && prevPage !== currPage) {
            getChatHistory();
        }
    }, [currPage, lastList, prevPage]);

    useEffect(() => {
        const handleKeyPress = (event: any) => {
            //windows & Mac CMD + K & CRT + K
            if (
                (event.metaKey && event.key === "k") ||
                (event.ctrlKey && event.key === "k")
            ) {
                setConversation([]);
                setInitialConversation(true);
            }
        };

        window.addEventListener("keydown", handleKeyPress);

        return () => {
            window.removeEventListener("keydown", handleKeyPress);
        };
    }, []);

    const onScroll = () => {
        if (listInnerRef.current) {
            const { scrollTop, scrollHeight, clientHeight } =
                listInnerRef.current;

            if (
                Math.round(scrollTop) + Math.round(clientHeight) ===
                Math.round(scrollHeight)
            ) {
                setCurrPage(currPage + 1);
            }
        }
    };

    const getChatHistory = async () => {
        const limit = 20;
        const getToken = await tokenHandler(instance);
        if (getToken) {
            try {
                const response = await ChatService.GetChatHistory(
                    `?offset=${currPage * limit}&limit=${limit}`,
                    getToken,
                );
                const loop: any = chatHistory;
                let sortData = {};
                if (currPage === 0) {
                    sortData = categorizeTasksByDate(response.data.items);
                } else {
                    const existingArray = reverseCategorizeTasksByDate(loop);
                    const combineArray = [
                        ...existingArray,
                        ...response.data.items,
                    ];
                    if (response.data.total === combineArray.length) {
                        setLastList(true);
                    }
                    sortData = categorizeTasksByDate(combineArray);
                }

                setPrevPage(currPage);
                setChatHistory(sortData);
            } catch (error: any) {
                if (error.response.status == 401) {
                    showBoundary(true);
                }
            }
        }
    };

    const getFirstPageChatHistory = async () => {
        const limit = 20;
        const getToken = await tokenHandler(instance);
        if (getToken) {
            try {
                const response = await ChatService.GetChatHistory(
                    `?offset=${0 * limit}&limit=${limit}`,
                    getToken,
                );

                const sortData = categorizeTasksByDate(response.data.items);

                setPrevPage(0);
                setChatHistory(sortData);
            } catch (error: any) {
                if (error.response.status == 401) {
                    showBoundary(true);
                }
            }
        }
    };

    const clearConversation = () => {
        setConversation([]);
        setInitialConversation(true);
    };

    const logOut = async () => {
        const homeAccountId = accounts[0].homeAccountId;
        const accountToSignOut = findAccountByHomeAccountId(homeAccountId);
        if (accountToSignOut) {
            signOutClickHandler(instance, accountToSignOut);
            await removeData(STORAGE_KEYS.CURRENT_CONVERSATION_ID);
            await removeData(STORAGE_KEYS.CHATGPT);
        } else {
            //Account not found for the given homeAccountId
            // console.error("Account not found for the given homeAccountId");
        }
    };

    const toggle = () => {
        setOpenStatus(!openStatus);
    };

    const toggleMobileSidebar = () => {
        setOpenStatus(!openStatus);
        setMobileSidebar(!mobileSidebar);
    };

    function reverseCategorizeTasksByDate(categorizedTasks: ChatMessage) {
        // convert into a single array
        const allTasks = [
            ...categorizedTasks.today,
            ...categorizedTasks.yesterday,
            ...categorizedTasks.older,
        ];

        allTasks.sort((a, b) => {
            const dateA: any = new Date(a.updated_at);
            const dateB: any = new Date(b.updated_at);
            return dateB - dateA; // newest first
        });

        return allTasks;
    }

    function categorizeTasksByDate(tasks: any) {
        const today = new Date();
        today.setHours(0, 0, 0, 0);

        const yesterday = new Date(today);

        yesterday.setDate(yesterday.getDate() - 1);

        const categorizedTasks: {
            today: Task[];
            yesterday: Task[];
            older: Task[];
        } = {
            today: [],
            yesterday: [],
            older: [],
        };
        // make today yesrday older array
        tasks.forEach((task: any) => {
            const taskDate = new Date(task.updated_at);

            if (taskDate >= today) {
                categorizedTasks.today.push(task);
            } else if (taskDate >= yesterday) {
                categorizedTasks.yesterday.push(task);
            } else {
                categorizedTasks.older.push(task);
            }
        });

        return categorizedTasks;
    }

    const GetProfilePicture = async () => {
        const getToken = await tokenHandler(instance, true);
        if (getToken) {
            try {
                const response = await ChatService.loadProfileImage(getToken);
                if (response.ok) {
                    const data = await response.blob();
                    const reader = new FileReader();
                    reader.onload = () => {
                        const base64data = reader.result;
                        setImage(base64data);
                    };
                    reader.readAsDataURL(data);
                }
            } catch (error) {
                console.error("Profile Picture Error");
            }
        }
    };

    const getGptModel = (model: string) => {
        if (model === STORAGE_KEYS.CHATGPT_VERSION_35) {
            setGptModel("Default (GPT-3.5)");
        }
        if (model === STORAGE_KEYS.CHATGPT_VERSION_4) {
            setGptModel("(GPT-4)");
        }
    };

    const conversationOnClick = async (id: string) => {
        setErorr({ code: 0, message: "" });
        setConversation([]);
        setOriginalConversation([]);
        setGptModel("");
        await setData(STORAGE_KEYS.CURRENT_CONVERSATION_ID, id);
        const getToken = await tokenHandler(instance);

        if (getToken) {
            try {
                const response = await ChatService.loadSingleConversation(
                    id,
                    getToken,
                );

                const responseObject = response.data.mappings;

                const originalArr = [];
                Object.values(responseObject).forEach((val: any) => {
                    const obj = {
                        id: val.id,
                        content: val.content,
                        parent_id: val.parent_id,
                        children: val.children,
                        role: val.role,
                        isSibling: false, // This will be automaticaaly added in generatearray function
                        siblings: [], // This will be automaticaaly added in generatearray function
                        conversationId: val.conversation_id,
                    };
                    originalArr.push(obj);
                });

                mainMapping.current = responseObject;
                const data = generateMessageArray(
                    response.data.current_node,
                    responseObject,
                    response.data.conversation_id,
                );

                getGptModel(response.data.gpt_model);
                setConversation(data);
                setOriginalConversation(originalArr);
            } catch (error: any) {
                if (error?.response?.status === 401 || error) {
                    showBoundary(true);
                }
            }
        }
        const selectedCnvId = await getData(
            STORAGE_KEYS.CURRENT_CONVERSATION_ID,
        );
        setSelectedConversationID(selectedCnvId);
        setInitialConversation(false);
    };

    const newThreadOnClick = async () => {
        setConversation([]);
        setOriginalConversation([]);
        setInitialConversation(true);
        await removeData(STORAGE_KEYS.CURRENT_CONVERSATION_ID);
        await removeData(STORAGE_KEYS.CHATGPT);
        setSelectedConversationID("");
        setErorr({ code: 0, message: "" });
    };

    const sendMessage = async (
        content: string,
        parentId: string,
        conversationId: string,
        isRegenerate: boolean = false,
    ) => {
        setTypeStart(true);
        const getToken = await tokenHandler(instance);
        const conversationDump = [...conversation];
        const originalConversationDump = [...originalConversation];
        let current_node_id;

        // get chat version
        let GptVersion = await getData(STORAGE_KEYS.CHATGPT);
        if (!GptVersion) {
            await setData(
                STORAGE_KEYS.CHATGPT,
                STORAGE_KEYS.CHATGPT_VERSION_35,
            );
            GptVersion = STORAGE_KEYS.CHATGPT_VERSION_35;
        }

        if (initialConversation) {
            getGptModel(GptVersion);
        }

        let answerIndex;
        for (let i = 0; i < conversationDump.length; i++) {
            if (conversationDump[i].role === "assistant") {
                answerIndex = i;
            }
        }

        if (!isRegenerate) {
            const newData = {
                id: null,
                content: content,
                children: [],
                conversationId: null,
                parent_id: null,
                isSibling: false,
                siblings: [],
                role: "user",
            };
            conversationDump.push(newData);
            setConversation([...conversationDump]);
            originalConversationDump.push(newData);
            setOriginalConversation(originalConversationDump);
        } else {
            const updatedArray = conversationDump.slice(0, -1);
            setConversation([...updatedArray]);
        }

        const body = {
            content: content,
            conversationId: conversationId || null,
            parentId: parentId || null,
            gptModelName: "",
        };

        //if initail conversation, sending gpt version & updated object
        if (initialConversation) {
            body.gptModelName = GptVersion;
        }

        const requestOptions = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${getToken}`,
            },
            body: JSON.stringify(body), // add your api parameters here
        };

        const response = await fetch(
            `${APIConstants.BASE_URL}/api/createMessage`,
            requestOptions,
        );

        if (response.body) {
            const reader = response.body.getReader();

            const headers = response.headers;
            const headersObj: any = {};

            for (const entry of headers.entries()) {
                headersObj[entry[0]] = entry[1];
            }

            const conversationId = response.headers.get("Conversationid");

            const resData = {
                id: response.headers.get("Parentmessageid"),
                content: "",
                children: [],
                conversationId: response.headers.get("Conversationid"),
                parent_id: null,
                isSibling: false,
                siblings: [],
                role: "user",
            };
            conversationDump.push(resData);

            let newVal = "";
            await setData(STORAGE_KEYS.CURRENT_CONVERSATION_ID, conversationId);
            setSelectedConversationID(conversationId);
            reader
                .read()
                .then(function processText({ done, value }): Promise<any> {
                    if (done) {
                        let parentIndex;
                        for (let i = conversationDump.length - 1; i >= 0; i--) {
                            // If isUser is true
                            if (conversationDump[i].role === "user") {
                                // update your object here
                                parentIndex = i;
                                break; // Exit the loop
                            }
                        }
                        conversationDump[parentIndex].id =
                            response.headers.get("Parentmessageid");
                        conversationDump[parentIndex].conversationId =
                            response.headers.get("Conversationid");
                        conversationDump[parentIndex].children.push(
                            response.headers.get("Currentmessageid"),
                        );
                        if (!isRegenerate) {
                            conversationDump[parentIndex].parent_id =
                                answerIndex && conversationDump[answerIndex].id;
                        }

                        if (answerIndex && !isRegenerate) {
                            conversationDump[answerIndex].children.push(
                                response.headers.get("Parentmessageid"),
                            );
                        }
                        setConversation([...conversationDump]);
                        current_node_id =
                            response.headers.get("Currentmessageid");
                        getChatHistory();
                        return;
                    }
                    newVal = newVal.concat(
                        new TextDecoder("utf-8").decode(value),
                    );

                    const newData = {
                        id: response.headers.get("Currentmessageid"),
                        content: newVal,
                        children: [],
                        conversationId: response.headers.get("Conversationid"),
                        parent_id: response.headers.get("Parentmessageid"),
                        isSibling: false,
                        siblings: [],
                        role: "assistant",
                    };

                    conversationDump[conversationDump.length - 1] = newData;

                    if (!isRegenerate) {
                        setConversation([...conversationDump]);
                        setTypeStart(false);
                    }

                    originalConversationDump.push(newData);
                    setOriginalConversation(originalConversationDump);

                    return reader.read().then(processText);
                })
                .finally(() => {
                    // setTypeStart(false);
                    organizeConversation(
                        current_node_id,
                        originalConversationDump,
                    );
                    setTypeStart(false);
                });
        }
    };

    const organizeConversation = (current_node_id, conversationDump) => {
        const outputObject = {};

        for (const item of conversationDump) {
            const id = item.id;
            const conversationId = item.conversationId;
            const parentId = item.parent_id || null; // Use null if parent_id is not defined
            const children = item.children || [];
            const role = item.role;
            const content = item.content || "";

            outputObject[id] = {
                id,
                conversation_id: conversationId,
                parent_id: parentId,
                children,
                role,
                content,
            };
        }
        mainMapping.current = outputObject;
        const finalArr = generateMessageArray(
            current_node_id,
            outputObject,
            conversationDump[0].conversationId,
        );

        setConversation(finalArr);
    };

    const handleButtonClick = async (content: string) => {
        try {
            const conversationDump = [...conversation];
            const lastMessage: any = conversationDump.pop();

            await sendMessage(
                content,
                lastMessage?.id,
                lastMessage?.conversationId,
            );
        } catch (error) {
            //TODO: Handle error
        }
    };

    const handleRegenerateClick = async () => {
        try {
            const filteredArr = conversation.filter(
                (obj: any) => obj.role === "user",
            );
            const lastUser: any = filteredArr[filteredArr.length - 1];
            await sendMessage(
                lastUser.content,
                lastUser.id,
                lastUser.conversationId,
                true,
            );
        } catch (error) {
            //TODO: Handle error
        }
    };

    function generateArrayTop(id, mappings, conversationId) {
        const messages = [];
        const current_node_id = id;
        let currentNode = mappings[current_node_id];
        while (currentNode) {
            messages.push({
                id: currentNode?.id,
                content: currentNode.content,
                parent_id: currentNode?.parent_id,
                children: currentNode?.children,
                role: currentNode.role,
                isSibling: false,
                siblings: [],
                conversationId: conversationId,
            });

            if (currentNode.children.length > 1) {
                const childElement = messages[messages.length - 2];
                childElement.isSibling = true;

                currentNode.children.map((c) => {
                    // if (c !== currentNode.id) {
                    childElement.siblings.push(c);
                    // }
                });
            }

            currentNode = mappings[currentNode.parent_id];
        }

        return messages;
    }

    const generateMessageArray = (currentId, mappings, conversationId) => {
        const res1 = generateArrayTop(currentId, mappings, conversationId);
        const rev = res1.reverse();
        const res2 = generateBottom(currentId, mappings, conversationId);
        const newArr = rev.concat(res2);

        const removeDummyItems = newArr.filter((item) => {
            return item.role !== "";
        });
        return removeDummyItems;
    };

    function generateBottom(id, mappings, conversationId) {
        const messages = [];
        const current_node_id = id;
        let currentNode = mappings[current_node_id];
        currentNode = mappings[currentNode?.children[0]];

        while (currentNode) {
            messages.push({
                id: currentNode?.id,
                content: currentNode.content,
                parent: currentNode?.parent_id,
                children: currentNode?.children,
                role: currentNode.role,
                conversationId: conversationId,
                isSibling: false,
                siblings: [],
            });

            if (currentNode.children.length > 1) {
                const childElement = mappings[currentNode.children[0]];

                const newChild = {
                    id: childElement?.id,
                    content: childElement.content,
                    parent: childElement?.parent_id,
                    children: childElement?.children,
                    role: childElement.role,
                    conversationId: conversationId,
                    isSibling: true,
                    siblings: [],
                };

                currentNode.children.map((c) => {
                    // if (c !== childElement.id) {
                    newChild.siblings.push(c);
                    // }
                });

                messages.push(newChild);
                currentNode = mappings[childElement?.children[0]];
                continue;
            }

            currentNode = mappings[currentNode.children[0]];
        }
        return messages;
    }

    const showNextSibling = (
        messageId: string,
        index: number,
        conversationId: string,
    ) => {
        const findMessageId: any = conversation.find(
            (x: any) => x.id === messageId,
        );
        const siblingArray = findMessageId.siblings;

        const data = generateMessageArray(
            siblingArray[index - 1],
            mainMapping.current,
            conversationId,
        );
        setConversation(data);
    };
    const conversationLength = conversation.length >= 2;

    return (
        <MsalAuthenticationTemplate interactionType={InteractionType.Redirect}>
            <ProtectedComponent />
            <div className="relative z-0 overflow-hidden ">
                <MobileHeader
                    toggle={toggleMobileSidebar}
                    onNewThread={newThreadOnClick}
                />
                <div className="min-h-screen w-full flex bg-ghostwhite ">
                    <div
                        className={`h-full sidebar-wrapper  ease-in-out duration-200 bg-primary-color fixed top-0 bottom-0 left-0 md:absolute md:top-0 md:bottom-0 md:left-[0px] md:z-50 ${
                            openStatus ? "w-[80px] md:w-[0px]" : "w-[250px]"
                        } `}
                    >
                        <div className="flex h-full min-h-0 flex-col ">
                            <div className="relative h-full w-full flex-1 items-start ">
                                <ModenavOpen
                                    logoImage="/image-1@3x.png"
                                    dimensionLabel="/vectors10.svg"
                                    showLog
                                    logoutUser={logOut}
                                    toggle={toggle}
                                    openStatus={openStatus}
                                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                                    // @ts-ignore
                                    data={chatHistory}
                                    onClick={conversationOnClick}
                                    onNewThread={newThreadOnClick}
                                    onScroll={onScroll}
                                    listInnerRef={listInnerRef}
                                    selectedConversationID={
                                        selectedConversationID
                                    }
                                    clearConversation={clearConversation}
                                    getFirstPageChatHistory={
                                        getFirstPageChatHistory
                                    }
                                />
                            </div>
                        </div>
                    </div>
                    <main
                        className={` ${
                            openStatus
                                ? "md:w-[0px] xxxl:w-[80px] xxxl:ml-[80px] md:ml-[0px]"
                                : " md:w-[0px] xxxl:w-[250px] xxxl:ml-[250px] md:ml-[0px]"
                        } flex-1 min-w-0 overflow-auto relative text-left ease-in-out duration-200 `}
                    >
                        <div className="flex-1 h-full overflow-hidden">
                            <ScrollToBottom className="scroll-wrapper">
                                {initialConversation ? (
                                    <div className="self-stretch flex flex-row flex-wrap items-center justify-center py-[20px] transition-all ease-in-out">
                                        <div className="flex flex-[.2] sm:flex-[0]"></div>
                                        <div className="flex flex-[.6] sm:flex-1 flex-col items-center justify-center">
                                            <GptSwitch
                                                disable={conversationLength}
                                            />
                                        </div>
                                        <div className="flex flex-[.2] flex-col items-end justify-center pr-[60px] sm:flex-[0] sm:hidden">
                                            <BtnProfile
                                                image={
                                                    image
                                                        ? image
                                                        : require("../../assets/images/profile.png")
                                                }
                                                profileName={accounts[0]?.name}
                                                email="Email text"
                                                showEmail={false}
                                                showName
                                                btnProfileCursor="unset"
                                                btnProfileBorder="none"
                                                btnProfilePadding="0"
                                                btnProfileBackgroundColor="transparent"
                                                profileWrapperJustifyContent="flex-end"
                                                profileWrapperBoxSizing="border-box"
                                                profileContentFlex="unset"
                                                profileNameAlignSelf="unset"
                                                profileNameJustifyContent="flex-start"
                                                btnTextFlex="unset"
                                            />
                                        </div>
                                    </div>
                                ) : (
                                    <div className="self-stretch flex flex-row justify-center items-center py-[10px] bg-white">
                                        <div className="flex flex-[.4] md:hidden"></div>
                                        <div className="flex flex-[1] flex-col items-center justify-center">
                                            <p className="font-museo-sans text-[14px]">
                                                {gptModel}
                                            </p>
                                        </div>
                                        <div className="flex flex-[.4] flex-col items-end justify-center pr-[60px] md:hidden">
                                            <BtnProfile
                                                image={
                                                    image
                                                        ? image
                                                        : require("../../assets/images/profile.png")
                                                }
                                                profileName={accounts[0]?.name}
                                                email="Email text"
                                                showEmail={false}
                                                showName
                                                btnProfileCursor="unset"
                                                btnProfileBorder="none"
                                                btnProfilePadding="0"
                                                btnProfileBackgroundColor="transparent"
                                                profileWrapperJustifyContent="flex-end"
                                                profileWrapperBoxSizing="border-box"
                                                profileContentFlex="unset"
                                                profileNameAlignSelf="unset"
                                                profileNameJustifyContent="flex-start"
                                                btnTextFlex="unset"
                                            />
                                        </div>
                                    </div>
                                )}
                                <div className="self-stretch flex-1 flex flex-col items-start justify-start pt-12 pb-[200px] sm:pb-[300px] px-0 gap-[24px] sm:pt-5">
                                    {conversation.map((message: any, index) => (
                                        <StyleUser
                                            key={index}
                                            messageText={
                                                message.role === "user"
                                                    ? image
                                                        ? image
                                                        : require("../../assets/images/profile.png")
                                                    : "/image3@2x.png"
                                            }
                                            message={message.content}
                                            isSibling={message.isSibling}
                                            siblingsArray={
                                                message.siblings || []
                                            }
                                            isUser={message.isUser}
                                            messageObject={message}
                                            dimensionText={
                                                message.role === "user"
                                                    ? "/vectors12.svg"
                                                    : "/vectors13.svg"
                                            }
                                            showIcon={
                                                message.role === "user"
                                                    ? false
                                                    : true
                                            }
                                            styleUserBackgroundColor={
                                                message.role === "user"
                                                    ? "unset"
                                                    : "#fff"
                                            }
                                            messageTextMargin="0"
                                            copyButtonCursor={
                                                message.role === "user"
                                                    ? "unset"
                                                    : "pointer"
                                            }
                                            copyButtonBorder={
                                                message.role === "user"
                                                    ? "unset"
                                                    : "none"
                                            }
                                            copyButtonPadding={
                                                message.role === "user"
                                                    ? "unset"
                                                    : "0"
                                            }
                                            copyButtonBackgroundColor={
                                                message.role === "user"
                                                    ? "unset"
                                                    : "transparent"
                                            }
                                            buttonBaseBoxSizing="border-box"
                                            icon
                                            loadedFromHistory={
                                                message?.loadedFromHistory
                                            }
                                            showNextSibling={showNextSibling}
                                            showPreviousSibling={
                                                showNextSibling
                                            }
                                            conversationId={
                                                message.conversationId
                                            }

                                            // loading={steamStatus}
                                        />
                                    ))}

                                    {typeStart && (
                                        <ChatLoading
                                            messageText="/image3@2x.png"
                                            message={""}
                                            isUser={false}
                                            isError={false}
                                            dimensionText={"/vectors12.svg"}
                                            showIcon={false}
                                            icon
                                            loadedFromHistory={true}
                                            loading={typeStart || steamStatus}
                                        />
                                    )}

                                    {error.message && (
                                        <ChatLoading
                                            messageText="/image3@2x.png"
                                            message={error.message}
                                            isUser={false}
                                            dimensionText={"/vectors12.svg"}
                                            showIcon={false}
                                            isError={true}
                                            icon
                                            loadedFromHistory={true}
                                            loading={false}
                                        />
                                    )}
                                </div>
                            </ScrollToBottom>
                        </div>
                        {error.code !== 500 && (
                            <div
                                className={`bg-red w-full pb-[40px] bg-transparent absolute md:fixed bottom-[0px] md:bottom-[0px] right-0 left-0 footer-input`}
                            >
                                <FormButton
                                    onSubmit={handleButtonClick}
                                    onRegenerate={handleRegenerateClick}
                                    conversation={conversation.length > 0}
                                    loading={steamStatus}
                                    onFocus={inputFocus}
                                />
                            </div>
                        )}
                    </main>
                </div>
            </div>
        </MsalAuthenticationTemplate>
    );
};

export default ConversationPage;
