import { FunctionComponent } from "react";
import StylebtnSecondary from "./stylebtn-secondary";
import ChatInput from "./chat-input";
import React from "react";

type FormButtonProps = {
    onSubmit: (value: string) => void;
    onRegenerate: () => void;
    conversation?: boolean;
    loading?: boolean;
    onFocus?: boolean;
};

const FormButton: FunctionComponent<FormButtonProps> = ({
    onSubmit,
    onRegenerate,
    conversation,
    loading,
    onFocus,
}) => {
    const handleButtonClick = (value: string) => {
        onSubmit(value);
    };

    const handleRegenerateClick = () => {
        onRegenerate();
    };

    return (
        <div className="self-stretch flex flex-col items-end justify-center py-0 px-16 gap-[16px] sm:pl-4 sm:pr-4 sm:box-border">
            {!loading && conversation && (
                <StylebtnSecondary
                    dimensionText="/vectors14.svg"
                    buttonText="Regenerate"
                    showBtnLabel={false}
                    showBtnIcon
                    stylebtnSecondaryBoxSizing="border-box"
                    stylebtnSecondaryCursor="pointer"
                    stylebtnSecondaryPadding="0"
                    stylebtnSecondaryBackgroundColor="transparent"
                    stylebtnSecondaryAlignSelf="unset"
                    stylebtnSecondaryBorder="1px solid #234389"
                    buttonBaseFlex="unset"
                    buttonBaseJustifyContent="center"
                    buttonBaseBoxSizing="border-box"
                    buttonBaseGap="12px"
                    btnContentWidth="unset"
                    vectorsIconWidth="9.25px"
                    vectorsIconHeight="10.8px"
                    btnTextFlex="unset"
                    btnTextColor="#234389"
                    labelDisplay="inline-block"
                    stylebtnSecondaryJustifyContent="flex-start"
                    btnContentFlex="unset"
                    btnTextTextAlign="left"
                    icon
                    label={false}
                    btnOnClick={handleRegenerateClick}
                />
            )}
            <ChatInput
                vector="/vector2.svg"
                chatInputJustifyContent="flex-start"
                chatInputPadding="24px"
                chatInputBoxSizing="border-box"
                chatInputBorder="unset"
                chatInputFontFamily="unset"
                chatInputFontSize="unset"
                chatInputGap="24px"
                inputSubmitJustifyContent="flex-start"
                enterButtonCursor="pointer"
                enterButtonBorder="none"
                enterButtonPadding="0"
                enterButtonBackgroundColor="transparent"
                onSubmit={handleButtonClick}
                loading={loading}
                onFocus={onFocus}
            />
        </div>
    );
};

export default FormButton;
