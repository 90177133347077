import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { useMsal } from "@azure/msal-react";
import { useEffect } from "react";
import { useToken } from "../context/authContext";

function ProtectedComponent() {
    const { instance, inProgress, accounts } = useMsal();
    const { setToken } = useToken();

    useEffect(() => {
        const accessTokenRequest = {
            // eslint-disable-next-line no-undef
            scopes: [`${process.env.REACT_APP_APP_CLIENT_ID}/Files.Read`],
            account: accounts[0],
        };
        if (inProgress === InteractionStatus.None) {
            instance
                .acquireTokenSilent(accessTokenRequest)
                .then(async (accessTokenResponse) => {
                    // Acquire token silent success
                    let accessToken = accessTokenResponse.accessToken;

                    setToken(accessToken);
                })
                .catch((error) => {
                    if (error instanceof InteractionRequiredAuthError) {
                        instance.acquireTokenRedirect(accessTokenRequest);
                    }
                });
        }
    }, [instance, accounts, inProgress]);

    return null;
}

export default ProtectedComponent;
