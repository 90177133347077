/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, RefObject } from "react";
import { FunctionComponent, useMemo, type CSSProperties } from "react";
import SmallSidebar from "../components/desktop-sidebar-small/index";
import SidebarItem from "./sidebar-item/index";
import { isMobile, isTablet } from "react-device-detect";

type Task = {
    id: string;
    title: string;
    create_time: string;
    update_time: string;
    conversation_template_id: string;
    current_node: string;
    gizmo_id: string;
};
// type MonthCategory = { month: string; tasks: Task[] };

type SidebarItemProps = {
    today: Task[];
    yesterday: Task[];
    older: Task[];
};

type ModenavOpenType = {
    logoImage?: string;
    dimensionLabel?: string;
    showLog?: boolean;
    logoutUser?: () => void;
    toggle?: () => void;
    onClick?: (id: string) => void;
    onNewThread?: () => void;
    data?: SidebarItemProps;
    openStatus?: boolean;
    onScroll?: () => void;
    selectedConversationID?: string;
    listInnerRef?: RefObject<HTMLDivElement>;
    getFirstPageChatHistory?: () => Promise<void>;
    clearConversation?: () => void;
};

const ModenavOpen: FunctionComponent<ModenavOpenType> = ({
    logoImage,
    dimensionLabel,
    logoutUser,
    toggle,
    openStatus,
    data,
    onClick,
    onNewThread,
    onScroll,
    listInnerRef,
    selectedConversationID,
    getFirstPageChatHistory,
    clearConversation,
}) => {
    const [showContent, setShowContent] = React.useState(isMobile || isTablet);

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setShowContent(!showContent);
        }, 200);

        return () => {
            clearTimeout(timer);
        };
    }, [openStatus]);

    return (
        <>
            {openStatus ? (
                <SmallSidebar toggle={toggle} />
            ) : (
                showContent && (
                    <div className="flex flex-col justify-stretch items-start box-border text-base py-[64px] text-base-white font-museo-sans bg-primary-color">
                        <div className="flex flex-col items-center self-stretch top-sidebar  py-3 pr-[22px] pl-[34px] pb-[20px]">
                            <div className="flex flex-row self-stretch items-center">
                                <div className="logo flex-1">
                                    <a>
                                        <img
                                            className="relative w-[85px] h-[45px] object-cover"
                                            alt=""
                                            src={logoImage}
                                        />
                                    </a>
                                </div>
                                <div className="toggle flex-1  flex flex-row justify-end">
                                    <a
                                        onClick={toggle}
                                        className="cursor-pointer"
                                    >
                                        <img
                                            className={`relative w-[17.5px] h-[17.5px]`}
                                            alt=""
                                            src="/vectors.svg"
                                        />
                                    </a>
                                </div>
                            </div>
                            <div className="flex-1 new-thread flex flex-row items-center justify-start self-stretch gap-[12px] pt-[40px]">
                                <div
                                    className="flex flex-row items-center justify-start gap-[10px] cursor-pointer"
                                    onClick={onNewThread}
                                >
                                    <div className="w-6 h-6 flex flex-row items-center justify-center">
                                        <img
                                            className="relative w-[15px] h-[15px]"
                                            alt=""
                                            src="/vectors1.svg"
                                        />
                                    </div>
                                    <p className="relative text-[16px] leading-[24px] pt-[1px]">
                                        New Thread
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div
                            className="middle-content h-[calc(100vh-300px)] sm:h-[320px] overflow-y-auto overflow-x-hidden self-stretch pt-[20px]"
                            onScroll={onScroll}
                            ref={listInnerRef}
                        >
                            <div className="pl-[22px] pr-[10px] h-full">
                                {data?.today && data?.today?.length > 0 && (
                                    <SidebarItem
                                        month={"Today"}
                                        tasks={data?.today}
                                        onClick={onClick}
                                        selectedConversation={
                                            selectedConversationID
                                        }
                                        getFirstPageChatHistory={
                                            getFirstPageChatHistory
                                        }
                                        clearConversation={clearConversation}
                                    />
                                )}
                                {data?.yesterday &&
                                    data?.yesterday.length > 0 && (
                                        <SidebarItem
                                            month={"Yesterday"}
                                            tasks={data?.yesterday}
                                            onClick={onClick}
                                            selectedConversation={
                                                selectedConversationID
                                            }
                                            getFirstPageChatHistory={
                                                getFirstPageChatHistory
                                            }
                                            clearConversation={
                                                clearConversation
                                            }
                                        />
                                    )}
                                {data?.older && data?.older.length > 0 && (
                                    <SidebarItem
                                        month={"Older"}
                                        tasks={data?.older}
                                        onClick={onClick}
                                        selectedConversation={
                                            selectedConversationID
                                        }
                                        getFirstPageChatHistory={
                                            getFirstPageChatHistory
                                        }
                                        clearConversation={clearConversation}
                                    />
                                )}
                            </div>
                        </div>
                        <div className="footer-sidebar flex flex-row fixed bottom-0 left-0 self-stretch px-[22px] w-[203px] pb-[20px]">
                            <div className="logout flex-1 flex flex-row">
                                <a
                                    className="logout flex-1 flex flex-row justify-start items-center cursor-pointer"
                                    onClick={logoutUser}
                                >
                                    <img
                                        className="relative w-[13.12px] h-[13.13px]"
                                        alt=""
                                        src={dimensionLabel}
                                    />
                                    <p className="pl-[15px] font-museo-sans">
                                        Logout
                                    </p>
                                </a>
                            </div>
                        </div>
                    </div>
                )
            )}
        </>
    );
};

export default ModenavOpen;
