import React from "react";
import { FunctionComponent, useMemo, type CSSProperties } from "react";

type BtnProfileType = {
    image?: string | ArrayBuffer;
    profileName?: string;
    email?: string;
    showEmail?: boolean;
    showName?: boolean;

    /** Style props */
    btnProfileCursor?: CSSProperties["cursor"];
    btnProfileBorder?: CSSProperties["border"];
    btnProfilePadding?: CSSProperties["padding"];
    btnProfileBackgroundColor?: CSSProperties["backgroundColor"];
    profileWrapperJustifyContent?: CSSProperties["justifyContent"];
    profileWrapperBoxSizing?: CSSProperties["boxSizing"];
    profileContentFlex?: CSSProperties["flex"];
    profileNameAlignSelf?: CSSProperties["alignSelf"];
    profileNameJustifyContent?: CSSProperties["justifyContent"];
    btnTextFlex?: CSSProperties["flex"];
};

const BtnProfile: FunctionComponent<BtnProfileType> = ({
    image,
    profileName = "Name text",
    email = "Email text",
    showEmail = true,
    showName = true,
    btnProfileCursor,
    btnProfileBorder,
    btnProfilePadding,
    btnProfileBackgroundColor,
    profileWrapperJustifyContent,
    profileWrapperBoxSizing,
    profileContentFlex,
    profileNameAlignSelf,
    profileNameJustifyContent,
    btnTextFlex,
}) => {
    const btnProfileStyle: CSSProperties = useMemo(() => {
        return {
            cursor: btnProfileCursor,
            border: btnProfileBorder,
            padding: btnProfilePadding,
            backgroundColor: btnProfileBackgroundColor,
        };
    }, [
        btnProfileCursor,
        btnProfileBorder,
        btnProfilePadding,
        btnProfileBackgroundColor,
    ]);

    const profileWrapperStyle: CSSProperties = useMemo(() => {
        return {
            justifyContent: profileWrapperJustifyContent,
            boxSizing: profileWrapperBoxSizing,
        };
    }, [profileWrapperJustifyContent, profileWrapperBoxSizing]);

    const profileContentStyle: CSSProperties = useMemo(() => {
        return {
            flex: profileContentFlex,
        };
    }, [profileContentFlex]);

    const profileNameStyle: CSSProperties = useMemo(() => {
        return {
            alignSelf: profileNameAlignSelf,
            justifyContent: profileNameJustifyContent,
        };
    }, [profileNameAlignSelf, profileNameJustifyContent]);

    const btnText1Style: CSSProperties = useMemo(() => {
        return {
            flex: btnTextFlex,
        };
    }, [btnTextFlex]);

    return (
        <div
            className="flex flex-row flex-wrap items-center justify-end text-left text-base text-primary-color font-museo-sans flex-1"
            style={btnProfileStyle}
        >
            <div
                className="flex-1 flex flex-row flex-wrap items-center justify-start p-3 gap-[12px]"
                style={profileWrapperStyle}
            >
                <div className="w-[34px] overflow-hidden shrink-0 flex flex-row items-center justify-between">
                    <img
                        className="relative rounded-[50%] w-[34px] h-[34px] object-cover"
                        alt=""
                        src={`${image}`}
                    />
                </div>
                <div
                    className="flex-1 flex flex-col items-start justify-center"
                    style={profileContentStyle}
                >
                    {showName && (
                        <div
                            className="self-stretch flex flex-row flex-wrap items-center justify-end"
                            style={profileNameStyle}
                        >
                            <div
                                className="flex-1 relative leading-[24px] inline-block min-h-[24px]"
                                style={btnText1Style}
                            >
                                {profileName}
                            </div>
                        </div>
                    )}
                    {showEmail && (
                        <div className="self-stretch flex flex-row flex-wrap items-center justify-end text-sm text-primary-grey">
                            <div className="flex-1 relative leading-[24px] inline-block min-h-[24px]">
                                {email}
                            </div>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default BtnProfile;
