export class APIConstants {
    /**
     * BASE_URL
     */
    static BASE_URL = process.env.REACT_APP_BACKEND_API as string;

    static API_CONVERSATION_HISTORY = "/api/conversations";
    static API_CHAT = "/api/createMessage";
    static API_LOAD_SINGLE_CONVERSATION = "/api/conversations";
    static API_LOAD_PROFILE_PICTURE =
        "https://graph.microsoft.com/v1.0/me/photo/$value";
}
