import React from "react";
import { FunctionComponent } from "react";
// import { Typewriter } from "react-simple-typewriter";

type StyleUserType = {
    messageText?: string;
    message?: string;
    dimensionText?: string;
    showIcon?: boolean;
    icon?: boolean;
    isUser?: boolean;
    loadedFromHistory?: boolean;
    loading?: boolean;
    isError?: boolean;
};

const ChatLoading: FunctionComponent<StyleUserType> = ({
    messageText,
    loading,
    message,
    isError = false,
}) => {
    return (
        <div
            className={`${
                isError && "bg-[#FFE4E4]"
            } flex flex-row items-center justify-start py-4 px-[69px] sm:px-[20px] box-border gap-[24px] text-left text-base text-gray font-museo-sans self-stretch`}
            // style={styleUserStyle}
        >
            <div className="flex-1 flex flex-row items-start justify-center gap-[24px]">
                <div className="flex flex-col items-start justify-start">
                    <img
                        className="relative rounded-[50%] w-[34px] h-[34px] object-cover"
                        alt=""
                        src={messageText}
                    />
                </div>
                <div className="flex-1 flex flex-row items-center justify-center">
                    <div
                        className="flex-1 relative leading-[24px]"
                        // style={messageTextStyle}
                    >
                        {loading && !isError ? (
                            <img
                                className="relative w-[34px] h-[34px] "
                                alt=""
                                src={require("../assets/images/loading.gif")}
                            />
                        ) : null}

                        {isError && (
                            <p className="mt-[7px] text-[16px] sm:text-[12px]">
                                {message}
                            </p>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ChatLoading;
