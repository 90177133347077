import React from "react";

type PropType = {
    toggle?: () => void;
};

const SmallSidebar = ({ toggle }: PropType) => {
    return (
        <div className=" pt-[20px] h-screen w-[80px] bg-primary-color md:hidden">
            <div className="pt-[48px]  w-full flex flex-col items-center">
                <a onClick={toggle} className="p-[20px] cursor-pointer">
                    <img
                        className="relative w-[17.5px] h-[17.5px] rotate-180"
                        alt=""
                        src="/vectors.svg"
                    />
                </a>
            </div>
        </div>
    );
};

export default SmallSidebar;
