import { useState } from "react";
import ModenavOpen from "../../components/modenav-open";
// import BtnProfile from "../../components/btn-profile";
import { useMsal } from "@azure/msal-react";
import { IPublicClientApplication, AccountInfo } from "@azure/msal-browser";
import React from "react";

function signOutClickHandler(
    instance: IPublicClientApplication,
    account: AccountInfo,
) {
    const logoutRequest = {
        account: account,
        mainWindowRedirectUri: "/",
        postLogoutRedirectUri: "/",
    };
    instance.logoutPopup(logoutRequest);
}

const Page = () => {
    const { instance, accounts } = useMsal();
    const [openStatus, setOpenStatus] = useState(false);

    // Function to find the AccountInfo based on homeAccountId
    const findAccountByHomeAccountId = (
        homeAccountId: string,
    ): AccountInfo | undefined => {
        return accounts.find(
            (account) => account.homeAccountId === homeAccountId,
        );
    };
    const logOut = () => {
        const homeAccountId = accounts[0].homeAccountId;
        const accountToSignOut = findAccountByHomeAccountId(homeAccountId);
        if (accountToSignOut) {
            signOutClickHandler(instance, accountToSignOut);
        } else {
            /* eslint-disable no-console */
            console.error("Account not found for the given homeAccountId");
        }
    };

    const toggle = () => {
        setOpenStatus(!openStatus);
    };
    return (
        <div>
            <div className="relative bg-ghostwhite w-full h-screen overflow-hidden flex flex-row">
                <div
                    className={`flex  ${
                        openStatus ? "w-[80px]" : "w-[250px]"
                    } duration-100 transition-all ease-linear`}
                >
                    <ModenavOpen
                        logoImage="/image-1@3x.png"
                        dimensionLabel="/vectors10.svg"
                        showLog
                        logoutUser={logOut}
                        toggle={toggle}
                        openStatus={openStatus}
                    />
                </div>
                <main className=" flex h-screen flex-col items-start justify-between py-16 px-0 relative text-left text-9xl text-gray font-museo-sans md:flex-1 md:self-stretch md:h-auto md:pt-8 md:pb-8 md:relative sm:h-full sm:gap-[0px] sm:pt-8 sm:px-0 sm:pb-4 sm:relative sm:w-full">
                    <div className="self-stretch flex flex-row flex-wrap items-center justify-between py-0 px-16 lg:flex-row lg:gap-[24px] lg:items-center lg:justify-start md:flex-row md:gap-[24px] md:items-center md:justify-start sm:w-full sm:h-auto sm:flex-row sm:gap-[24px] sm:items-center sm:justify-between sm:pl-4 sm:pr-4 sm:box-border">
                        <div className="flex-1 h-[58px] flex flex-col items-start justify-start py-[20px] px-0 box-border sm:w-[85%]">
                            <p className="m-0 flex-1 relative text-[20px] font-normal font-inherit">
                                404 ERROR
                            </p>
                            <h1 className="m-0 flex-1 relative text-[40px] font-normal font-inherit pt-[15px]">
                                Oops! Page not found.
                            </h1>
                        </div>
                    </div>
                </main>
            </div>
        </div>
    );
};

export default Page;
