export const setData = async (key, value) => {
    try {
        const jsonValue = JSON.stringify(value);
        localStorage.setItem(key, jsonValue);
    } catch (e) {
        // saving error
    }
};

export const getData = async (key) => {
    try {
        const jsonValue = localStorage.getItem(key);
        return jsonValue != null ? JSON.parse(jsonValue) : null;
    } catch (e) {
        // error reading value
    }
};

export const removeData = async (key) => {
    try {
        localStorage.removeItem(key);
    } catch (e) {
        // remove error
    }
};
