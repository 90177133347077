import React, { useEffect } from "react";
import {
    Routes,
    Route,
    useNavigationType,
    useLocation,
} from "react-router-dom";
import LocofyTemplate1 from "./pages/conversation-page";
import Login from "./pages/login";
import Page404 from "./pages/404-page";
import Loading from "./components/loading/index";
import { useIsAuthenticated, useMsal } from "@azure/msal-react";
import { InteractionStatus } from "@azure/msal-browser";

const App = () => {
    const action = useNavigationType();
    const location = useLocation();
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();
    const pathname = location.pathname;

    useEffect(() => {
        if (action !== "POP") {
            window.scrollTo(0, 0);
        }
    }, [action, pathname]);

    useEffect(() => {
        let title = "";
        let metaDescription = "";

        switch (pathname) {
            case "/":
                title = "";
                metaDescription = "";
                break;
            case "/preview":
                title = "";
                metaDescription = "";
                break;
        }

        if (title) {
            document.title = title;
        }

        if (metaDescription) {
            const metaDescriptionTag: HTMLMetaElement | null =
                document.querySelector('head > meta[name="description"]');
            if (metaDescriptionTag) {
                metaDescriptionTag.content = metaDescription;
            }
        }
    }, [pathname]);

    if (!isAuthenticated && inProgress !== InteractionStatus.None) {
        return <Loading />;
    }

    return (
        <Routes>
            <Route path="/preview" element={<LocofyTemplate1 />} />
            <Route path="/preview/:id" element={<LocofyTemplate1 />} />
            <Route path="/" element={<Login />} />
            <Route path="*" element={<Page404 />} />
        </Routes>
    );
};
export default App;
