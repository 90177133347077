import { CopyToClipboard } from "react-copy-to-clipboard";
import ReactMarkdown from "react-markdown";
import hljs from "highlight.js";
import "highlight.js/styles/github.css";
// import { toast } from "react-toastify";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";

export const markdownEditor = (messages: any) => {
    const text = messages.toString();
    return (
        <div>
            <ReactMarkdown
                // eslint-disable-next-line react/no-children-prop
                children={text}
                className="mark-down-editor  self-stretch"
                remarkPlugins={[remarkGfm]}
                rehypePlugins={[rehypeRaw]}
                components={{
                    pre({ ...props }) {
                        return (
                            <pre {...props} className="hljs overflow-x-auto" />
                        );
                    },
                    code({ inline, className, children, ...props }: any) {
                        const match = /language-(\w+)/.exec(className || "");
                        return !inline && match ? (
                            <CopyToClipboard
                                text={String(children)}
                                onCopy={() => {
                                    // toast.success("Copied !");
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <code
                                    {...props}
                                    className={`language-${match[1]} overflow-x-auto`}
                                    dangerouslySetInnerHTML={{
                                        __html: hljs.highlight(
                                            String(children),
                                            {
                                                language: match[1],
                                                ignoreIllegals: true,
                                            },
                                        ).value,
                                    }}
                                ></code>
                            </CopyToClipboard>
                        ) : (
                            <CopyToClipboard
                                text={String(children)}
                                onCopy={() => {
                                    // toast.success("Copied !");
                                }}
                                style={{ cursor: "pointer" }}
                            >
                                <>{children}</>
                            </CopyToClipboard>
                        );
                    },
                }}
            />
        </div>
    );
};
