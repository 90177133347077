import React, { createContext, useState, ReactNode, useContext } from "react";

//  types for the AuthContext value
type AuthContextType = {
    token: string | null;
    setToken: (token: string | null) => void;
};

// AuthContext
const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const useToken = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("useSwitch must be used within a AuthContext");
    }
    return context;
};

// AuthProvider props
type AuthProviderProps = {
    children: ReactNode;
};

const AuthProvider = ({ children }: AuthProviderProps) => {
    const [token, setToken] = useState<string | null>(null);
    return (
        <AuthContext.Provider value={{ token, setToken }}>
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext, AuthProvider };
