import { AuthRes, SendMessage } from "./types/index";
import { APIConstants } from "./api-constant";
import { APIHelper } from "./api-helpers";

export class ChatService {
    static async GetChatHistory(param: string, token: string): Promise<any> {
        const authBody: AuthRes = {
            baseUrl: APIConstants.BASE_URL,
            authKey: token,
            authType: "Bearer",
        };
        return new Promise<any>((resolve, reject) => {
            APIHelper.api(authBody)
                .get<any>(`${APIConstants.API_CONVERSATION_HISTORY}${param}`)
                .then((res) => {
                    const data = res.data;
                    if (res.status === 200) {
                        resolve(data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static async SendMessage(
        msgBody: SendMessage,
        token: string,
    ): Promise<any> {
        const authBody: AuthRes = {
            baseUrl: APIConstants.BASE_URL,
            authKey: token,
            authType: "Bearer",
        };
        return new Promise<any>((resolve, reject) => {
            APIHelper.api(authBody)
                .post<any>(APIConstants.API_CHAT, msgBody)
                .then((res) => {
                    const data = res;
                    if (res.status === 200) {
                        resolve(data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static async loadSingleConversation(
        id: string,
        token: string,
    ): Promise<any> {
        const authBody: AuthRes = {
            baseUrl: APIConstants.BASE_URL,
            authKey: token,
            authType: "Bearer",
        };
        return new Promise<any>((resolve, reject) => {
            APIHelper.api(authBody)
                .get<any>(`${APIConstants.API_LOAD_SINGLE_CONVERSATION}/${id}`)
                .then((res) => {
                    const data = res.data;
                    if (res.status === 200) {
                        resolve(data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static async loadProfileImage(token: string): Promise<any> {
        const headers = new Headers();
        const bearer = `Bearer ${token}`;

        headers.append("Authorization", bearer);
        headers.append("Content-type", "image/png");

        const options = {
            method: "GET",
            headers: headers,
        };

        const response = await fetch(
            "https://graph.microsoft.com/v1.0/me/photo/$value",
            options,
        );
        return response;
    }

    static async editConversationTitle(
        token: string,
        conversationId: string,
        title: string,
    ): Promise<any> {
        const authBody: AuthRes = {
            baseUrl: APIConstants.BASE_URL,
            authKey: token,
            authType: "Bearer",
        };
        return new Promise<any>((resolve, reject) => {
            APIHelper.api(authBody)
                .patch<any>(
                    `${APIConstants.API_LOAD_SINGLE_CONVERSATION}/${conversationId}`,
                    { title },
                )
                .then((res) => {
                    const data = res.data;
                    if (res.status === 200) {
                        resolve(data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }

    static async deleteConversation(
        token: string,
        conversationId: string,
    ): Promise<any> {
        const authBody: AuthRes = {
            baseUrl: APIConstants.BASE_URL,
            authKey: token,
            authType: "Bearer",
        };
        return new Promise<any>((resolve, reject) => {
            APIHelper.api(authBody)
                .delete<any>(
                    `${APIConstants.API_LOAD_SINGLE_CONVERSATION}/${conversationId}`,
                )
                .then((res) => {
                    const data = res.data;
                    if (res.status === 200) {
                        resolve(data);
                    }
                })
                .catch((error) => {
                    reject(error);
                });
        });
    }
    static async postMessage(token: string, body: object): Promise<any> {
        const options = {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${token}`,
            },
            body: JSON.stringify(body),
        };

        const response = await fetch(
            `${APIConstants.BASE_URL}${APIConstants.API_CHAT}`,
            options,
        );

        return response;
    }
}
