import React from "react";

type PropTypes = {
    toggle?: () => void;
    onNewThread?: () => void;
};

const MobileHeader = ({ toggle, onNewThread }: PropTypes) => {
    return (
        <div className="flex flex-row mobile-header  bg-primary-color items-center justify-center px-[15px] py-[10px] md:flex">
            <div className="left-panel flex-1 flex-col items-center justify-center ">
                <a onClick={toggle} className="cursor-pointer z-50">
                    <img
                        src={require("../../assets/images/hamburger-menu.png")}
                        className="w-[34px] mt-[2px]"
                    />
                </a>
            </div>
            <div className="right-panel flex flex-1 flex-row justify-end">
                <a onClick={onNewThread} className="cursor-pointer">
                    <img
                        src={require("../../assets/images/plus-icon.png")}
                        className="w-[30px]"
                    />
                </a>
            </div>
        </div>
    );
};

export default MobileHeader;
