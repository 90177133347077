/* eslint-disable @typescript-eslint/no-unused-vars */
import React from "react";
import { FunctionComponent, useMemo, type CSSProperties } from "react";

type StylebtnSecondaryType = {
    dimensionText?: string;
    buttonText?: string;
    showBtnLabel?: boolean;
    showBtnIcon?: boolean;
    icon?: boolean;
    label?: boolean;
    btnOnClick?: () => void;

    /** Style props */
    stylebtnSecondaryBoxSizing?: CSSProperties["boxSizing"];
    stylebtnSecondaryCursor?: CSSProperties["cursor"];
    stylebtnSecondaryPadding?: CSSProperties["padding"];
    stylebtnSecondaryBackgroundColor?: CSSProperties["backgroundColor"];
    stylebtnSecondaryAlignSelf?: CSSProperties["alignSelf"];
    stylebtnSecondaryBorder?: CSSProperties["border"];
    buttonBaseFlex?: CSSProperties["flex"];
    buttonBaseJustifyContent?: CSSProperties["justifyContent"];
    buttonBaseBoxSizing?: CSSProperties["boxSizing"];
    buttonBaseGap?: CSSProperties["gap"];
    btnContentWidth?: CSSProperties["width"];
    vectorsIconWidth?: CSSProperties["width"];
    vectorsIconHeight?: CSSProperties["height"];
    btnTextFlex?: CSSProperties["flex"];
    btnTextColor?: CSSProperties["color"];
    labelDisplay?: CSSProperties["display"];
    stylebtnSecondaryJustifyContent?: CSSProperties["justifyContent"];
    btnContentFlex?: CSSProperties["flex"];
    btnTextTextAlign?: CSSProperties["textAlign"];
};

const StylebtnSecondary: FunctionComponent<StylebtnSecondaryType> = ({
    dimensionText,
    buttonText,
    showBtnLabel,
    showBtnIcon,
    stylebtnSecondaryBoxSizing,
    stylebtnSecondaryCursor,
    stylebtnSecondaryPadding,
    stylebtnSecondaryBackgroundColor,
    stylebtnSecondaryAlignSelf,
    stylebtnSecondaryBorder,
    buttonBaseFlex,
    buttonBaseJustifyContent,
    buttonBaseBoxSizing,
    buttonBaseGap,
    btnContentWidth,
    vectorsIconWidth,
    vectorsIconHeight,
    btnTextFlex,
    btnTextColor,
    labelDisplay,
    stylebtnSecondaryJustifyContent,
    btnContentFlex,
    btnTextTextAlign,
    // icon,
    // label,
    btnOnClick,
}) => {
    const stylebtnSecondaryStyle: CSSProperties = useMemo(() => {
        return {
            boxSizing: stylebtnSecondaryBoxSizing,
            cursor: stylebtnSecondaryCursor,
            padding: stylebtnSecondaryPadding,
            backgroundColor: stylebtnSecondaryBackgroundColor,
            alignSelf: stylebtnSecondaryAlignSelf,
            border: stylebtnSecondaryBorder,
            justifyContent: stylebtnSecondaryJustifyContent,
        };
    }, [
        stylebtnSecondaryBoxSizing,
        stylebtnSecondaryCursor,
        stylebtnSecondaryPadding,
        stylebtnSecondaryBackgroundColor,
        stylebtnSecondaryAlignSelf,
        stylebtnSecondaryBorder,
        stylebtnSecondaryJustifyContent,
    ]);

    const buttonBaseStyle: CSSProperties = useMemo(() => {
        return {
            flex: buttonBaseFlex,
            justifyContent: buttonBaseJustifyContent,
            boxSizing: buttonBaseBoxSizing,
            gap: buttonBaseGap,
        };
    }, [
        buttonBaseFlex,
        buttonBaseJustifyContent,
        buttonBaseBoxSizing,
        buttonBaseGap,
    ]);

    const btnContentStyle: CSSProperties = useMemo(() => {
        return {
            width: btnContentWidth,
            flex: btnContentFlex,
        };
    }, [btnContentWidth, btnContentFlex]);

    const vectorsIcon1Style: CSSProperties = useMemo(() => {
        return {
            width: vectorsIconWidth,
            height: vectorsIconHeight,
        };
    }, [vectorsIconWidth, vectorsIconHeight]);

    const btnTextStyle: CSSProperties = useMemo(() => {
        return {
            flex: btnTextFlex,
            color: btnTextColor,
            textAlign: btnTextTextAlign,
        };
    }, [btnTextFlex, btnTextColor, btnTextTextAlign]);

    const labelStyle: CSSProperties = useMemo(() => {
        return {
            display: labelDisplay,
        };
    }, [labelDisplay]);

    return (
        <button
            className="box-border flex flex-row flex-wrap items-center justify-start text-left text-base text-primary-color font-museo-sans border-[1px] border-solid border-primary-color"
            style={stylebtnSecondaryStyle}
            onClick={btnOnClick}
        >
            <div
                className="flex-1 flex flex-row flex-wrap items-center justify-start p-3 sm:p-[10px] gap-[12px] bg-ghostwhite"
                style={buttonBaseStyle}
            >
                <div
                    className="w-[102px] flex flex-row items-center justify-start gap-[6px] "
                    style={btnContentStyle}
                >
                    {showBtnIcon && (
                        <div className="w-6 h-6 flex flex-row items-center justify-center">
                            <img
                                className="relative w-[17.5px] h-[17.5px]"
                                alt=""
                                src={dimensionText}
                                style={vectorsIcon1Style}
                            />
                        </div>
                    )}
                    <div
                        className="flex-1 relative leading-[24px] sm:leading-[16px] inline-block min-h-[24px]  sm:min-h-[18px] "
                        // style={btnTextStyle}
                    >
                        {buttonText}
                    </div>
                </div>
                {showBtnLabel && (
                    <div className="w-11 flex flex-row items-center justify-end text-right text-xs text-royalblue">
                        <div
                            className="relative leading-[38.08px]"
                            style={labelStyle}
                        >
                            CMD+K
                        </div>
                    </div>
                )}
            </div>
        </button>
    );
};

export default StylebtnSecondary;
