import { loginRequest } from "../config/authConfig";
import { IPublicClientApplication } from "@azure/msal-browser";

export async function tokenHandler(
    instance: IPublicClientApplication,
    profile: boolean = false,
): Promise<string> {
    const accounts: any = instance.getAllAccounts();
    let scope = [];
    if (profile) {
        scope = ["User.Read", "profile", "openid", "offline_access"];
    } else {
        scope = [`${process.env.REACT_APP_APP_CLIENT_ID}/Files.Read`];
    }
    try {
        const token = await instance.acquireTokenSilent({
            scopes: scope,
            account: accounts[0], // Assuming you want to acquire tokens for the first account
            // You can adjust this based on your use case
        });

        return token.accessToken;
    } catch (error) {
        console.error(error);
        try {
            const resp = await instance.acquireTokenPopup(loginRequest);
            return resp.accessToken;
        } catch (popupError) {
            console.error(popupError);
            throw Error();
        }
    }
}
